<template>
    <div class="page">
        <div v-if="detailTitle||detailObj.title">
            <div class="bread-crumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item @click.native="change">{{detailTitle?detailTitle:detailObj.title}}</el-breadcrumb-item>
                    <el-breadcrumb-item>详情</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="box">
                <h2>{{detailObj.title}}</h2>
                <h3 v-if="detailObj.subtitle">{{detailObj.subtitle}}</h3>
                <ul>
                    <li>作者：{{detailObj.author}}</li>
                    <li v-if="detailObj.createTime">  发布时间：{{detailObj.createTime.substring(0,10)}}</li>
                    <li v-if="detailObj.lookNum">  浏览量：{{detailObj.lookNum}}</li>
                </ul>
                <p v-html="detailObj.content"></p>
                <template v-if="detailObj.fileList&&detailObj.fileList[0]!=null">
                    <!-- <div>{{ detailObj.fileList.length }}</div> -->
                    <div v-for="(item,index) of detailObj.fileList" :key="index">
                        <a v-if="item.address" :href="item.address">{{item.name}}</a>
                    </div>
                </template>
<!-- <a v-if="detailObj.uploadFiles" :href="detailObj.uploadFiles">{{detailObj.uploadFiles}}</a> -->
</div>
</div>
<div v-else>
    <div class="party-item" v-for="(item,index) of conList" @click="toChange(item.type,item)" :key="index">
        <!-- <img v-if="index!=0&&tableList[0]=='党建工作'" :src="item.path" alt=""> -->
        <div class="cover-img" v-if="item.path">
            <img v-if="item.path.includes(',')" v-lazy="item.path.split(',')[0]" alt="">
            <img v-else v-lazy="item.path" alt="">
        </div>
        <!-- <img v-if="tableList[0]!='党建工作'" :src="item.path" alt=""> -->
        <div class="party-font">
            <div>
                <h2>{{item.title}}</h2>
                <!-- v-html里的图片懒加载 -->
                <p v-lazy-container="{selector:'img'}" v-html="item.content"></p>
            </div>
            <ul>
                <li># {{item.author?item.author:'来源'}}</li>
                <li v-if="item.createTime">{{item.createTime.substring(0,10)}}</li>
            </ul>
        </div>
        <span>{{tableList[item.type-1]}}</span>
    </div>
</div>

</div>
</template>
<script>
    export default {
        props: ['tableList', 'conList', 'detailObj'],
        data() {
            return {
                detailTitle: '',
            }
        },
        created() {
            if (this.$route.query.detailTitle) {
                this.$parent.getDetail(this.$route.query.articleId);
            } else {
                this.$parent.change(1, 'list');
            }
        },
        watch: {
            $route: {
                handler(val, oldval) {
                    if (val.query.articleId) this.$parent.getDetail(val.query.articleId);
                    else this.$parent.change(1, 'list');
                    this.detailTitle=this.$route.query.detailTitle;
                },
                // 深度观察监听
                deep: true
            }
        },
        methods: {
            toChange(type, item) {
                // this.detailTitle = this.tableList[type == 1 ? 0 : 1];
                console.log(type);
                console.log(this.tableList);
                this.detailTitle = this.tableList[type - 1];
                // 跳转当前列表详情页
                let path = location.href.split('#')[1];
                if (path.includes('?')) path = path.split('?')[0];
                let query = {};
                if (this.$route.query.id) query.id = this.$route.query.id;
                query.articleId = item.id;
                query.detailTitle = this.detailTitle;
                this.$router.push({
                    path: path,
                    query: query
                })
            },
            change() {
                this.detailTitle = '';
                // 跳转当前列表详情页
                let path = location.href.split('#')[1];
                if (path.includes('?')) path = path.split('?')[0];
                let query = {};
                if (this.$route.query.id) query.id = this.$route.query.id;
                this.$router.push({
                    path: path,
                    query: query
                })

                // this.$parent.change(1, 'list');
            },
        }
    }
</script>
<style scoped lang="scss">
    .box {
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        margin-bottom: 20px;
        padding: 20px;
        box-sizing: border-box;
        position: relative;
    }
    
    .box p ::v-deep img {
        width: auto;
        max-width: 100%;
        display: inline-block;
        margin: auto;
    }
    
    .party-item {
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        margin-bottom: 20px;
        padding: 20px;
        box-sizing: border-box;
        position: relative;
        display: flex;
        cursor: pointer;
        h2 {
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #050000;
            margin-bottom: 10px;
        }
        p {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-bottom: 20px;
            word-break: break-all;
            max-height: 80px;
        }
        ul {
            width: 30%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            li:first-child {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #186EC5;
            }
            li:last-child {
                font-size: 14px;
                font-family: Georgia-Bold, Georgia;
                font-weight: bold;
                color: #999999;
            }
        }
        span {
            position: absolute;
            height: 24px;
            background: rgba(197, 24, 31, .2);
            border-radius: 0px 4px 0px 4px;
            right: 0;
            top: 0;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #C5181F;
            line-height: 24px;
            padding: 0 12px;
            box-sizing: border-box;
        }
        img {
            width: 100%;
        }
    }
    
    .cover-img {
        width: 240px;
        height: 124px;
        margin-right: 20px;
    }
    
    .party-font {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: calc(100% - 260px);
    }
    
    .box {
        h2 {
            font-size: 28px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #050000;
            text-align: center;
            margin-bottom: 10px;
        }
        h3 {
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 400;
            color: #050000;
            text-align: right;
            margin-bottom: 10px;
            text-align: right !important;
        }
        ul {
            width: 100%;
            display: flex;
            justify-content: center;
            li {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #186EC5;
                margin-right: 8px;
            }
            li:last-child {
                margin-right: 0;
            }
        }
        p {
            margin-top: 20px;
            word-break: break-all;
        }
    }
    
    .bread-crumb {
        cursor: pointer;
    }
    .party-font ::v-deep .Section0>div{
        min-height: auto !important;
    }
    @media screen and (max-width: 1200px) {
        .party-item ul {
            width: 100%;
        }
    }
    
    @media screen and (max-width: 750px) {
        .cover-img {
            width: 100%;
            height: auto;
            margin-right: 0;
            margin-top: .4rem;
        }
        .cover-img img {
            position: relative;
            height: auto;
        }
        .party-item h2 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    
    @media screen and (max-width: 560px) {
        .party-item {
            display: block;
            ul {
                justify-content: flex-start;
                align-items: center;
                li {
                    line-height: .5rem;
                }
                li:first-child {
                    margin-right: .27rem;
                }
            }
            img {
                width: 100%;
            }
        }
        .party-font {
            width: 100%;
        }
    }
</style>