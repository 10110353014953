<template>
    <div class="know">
        <HeaderPic :topId="topId" :topTitle="topText"></HeaderPic>
        <div class="know-main">
            <div class="know-left">
            <ul class="know-table-list">
                <li :class="{ active: ind == index }" @click="change(index)" v-for="(item,index) of tableList" :key="item">
                <img v-if="ind == index" src="../assets/img/know-school.png" alt=""><span>{{item}}</span>
                </li>
            </ul>
        </div>
        <div class="know-right">
          <div class="know-title">
            {{ind==-1?'教学科研':tableList[ind]}}
            <img src="../assets/img/know-right-title.png" alt="">
          </div>
            <!--  -->
            <PartyItem ref="child" :conList="conList" :detailObj="detailObj" :tableList="tableList"></PartyItem>
            <el-pagination v-if="pageShow" :current-page.sync="pageNum" :page-size="pageSize" @current-change="handleCurrentChange" layout="prev, pager, next" :total="total"></el-pagination>
        </div>
      </div>
    </div>
  </template>
<script>
    import HeaderPic from '../components/HeaderPic.vue'
    import PartyItem from '../components/Party/PartyItem.vue'
    import {
        teachResearch,
        teachResearchDetail,
        increamPageView,
        getPageViewByNameAndId
    } from "@/request/api";
    export default {
        components: {
            HeaderPic,
            PartyItem
        },
        data() {
            return {
                topPicture: '',
                topText: '',
                topId: '',
                tableList: [],
                ind: -1,
                conList: [],
                detailObj: {},
                // 分页
                total: 0,
                pageNum: 1,
                pageSize: 10,
                pageShow: true
            }
        },
        created() {
            this.initTableList();
            this.teachResearch();

        },
        mounted() {
            if (this.$route.query.id) {
                this.ind = this.$route.query.id - 1;
                this.change(this.ind);
            }
        },
        watch: {
            $route: {
                handler(val, oldVal) {
                    // console.log(val.query.id); //新路由信息
                    if (val.query.id) {
                        this.ind = this.$route.query.id - 1;
                        this.change(this.ind);
                    }
                    // this.partyBranch();
                },
                // 深度观察监听
                deep: true
            }
        },
        methods: {
            initTableList() {
                this.tableList = []
                for (let item of this.headerList[6].content) {
                    this.tableList.push(item.text)
                }
                this.topText = this.headerList[6].title;
                this.topId = this.headerList[6].id;
                // this.menuPic(this.headerList[6].id);
            },
            change(index, type) {
                if (!type) {
                    this.ind = index;
                    this.$refs.child.change();
                    this.teachResearch();
                }
                this.detailObj = {};
                this.pageShow = true;
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.teachResearch();
            },
            teachResearch() {
                const _this = this;
                let data = {
                    type: parseInt(_this.ind) + 1, //活动类型（1：教师培训；2：嘉小课堂；3：学科活动；4：教师风采；4：成果展示）
                    pageNum: _this.pageNum,
                    pageSize: _this.pageSize
                }
                teachResearch(data).then(res => {
                    _this.conList = res.rows;
                    _this.total = res.total;
                    if (_this.total == 0) _this.pageShow = false;
                    else _this.pageShow = true;
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            getDetail(id) {
                const _this = this;
                // _this.increamPageView(id);
                let data = {
                    id: id
                }
                teachResearchDetail(data).then(res => {
                    _this.detailObj = res;
                    // _this.detailObj.title = _this.$route.query.detailTitle;
                    _this.getPageViewByNameAndId(id);
                    _this.pageShow = false;
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            //浏览量的增加
            increamPageView(id) {
                // const _this = this;
                let data = {
                    pageName: this.topText,
                    pageId: id
                };
                increamPageView(data).then(res => {
                    // console.log(res);
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            //获取当前页面浏览量
            getPageViewByNameAndId(id) {
                const _this = this;
                let data = {
                    pageName: this.topId,
                    pageId: id
                };
                getPageViewByNameAndId(data).then(res => {
                    _this.detailObj.lookNum = res.data;
                    // console.log(res);
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
        }
    }
</script>
<style scoped lang="scss">
    @import "../../public/css/frame.scss";
</style>